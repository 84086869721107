
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Shared Moving Inventory - '
  },
  name: 'SharedMovingInventory',
  components: {},
  data: () => ({}),
  computed: {}
});
